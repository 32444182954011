import cjk from "cjk-regex"
import moment from "moment"
import { success } from "sass/_02_variables.scss"
import {
  defaultColor,
  defaultDirection,
  defaultLocale,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey
} from "../constants/defaultValues"

export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key]
    const B = b[key]
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1
    }
    return -1
  })
  return array
}

export const getCurrentTime = () => {
  const now = new Date()
  return `${now.getHours()}:${now.getMinutes()}`
}

export const getDirection = () => {
  let direction = defaultDirection

  try {
    if (localStorage.getItem("direction")) {
      const localValue = localStorage.getItem("direction")
      if (localValue === "rtl" || localValue === "ltr") {
        direction = localValue
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error)
    direction = defaultDirection
  }
  return {
    direction,
    isRtl: direction === "rtl"
  }
}

export const setDirection = localValue => {
  let direction = "ltr"
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue
  }
  try {
    localStorage.setItem("direction", direction)
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error)
  }
}

export const getCurrentColor = () => {
  let currentColor = defaultColor
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey)
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error)
    currentColor = defaultColor
  }
  return currentColor
  // return "light.bluenavy"
}

export const setCurrentColor = color => {
  try {
    localStorage.setItem(themeColorStorageKey, color)
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error)
  }
}

export const getCurrentRadius = () => {
  return "flat"
}
export const setCurrentRadius = radius => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius)
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentRadius -> error", error)
  }
}

export const getCurrentLanguage = () => {
  let language = defaultLocale

  try {
    language =
      localStorage.getItem("currentLanguage") &&
      localeOptions.filter(
        x => x.id === localStorage.getItem("currentLanguage")
      ).length > 0
        ? localStorage.getItem("currentLanguage")
        : defaultLocale
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : getCurrentLanguage -> error",
      error
    )
    language = defaultLocale
  }
  return language
}

export const setCurrentLanguage = locale => {
  try {
    localStorage.setItem("currentLanguage", locale)
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : setCurrentLanguage -> error",
      error
    )
  }
}

export const getCurrentUser = () => {
  let user = null
  try {
    user =
      localStorage.getItem("gogo_current_user") != null
        ? JSON.parse(localStorage.getItem("gogo_current_user"))
        : null
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error)
    user = null
  }
  return user
}

export const setCurrentUser = user => {
  try {
    if (user) {
      localStorage.setItem("gogo_current_user", JSON.stringify(user))
    } else {
      localStorage.removeItem("gogo_current_user")
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error)
  }
}

export const getRenderRole = (role, formatMessage) => {
  switch (role) {
    case 1:
      return formatMessage({ id: "role.system-admin" })
    case 2:
      return formatMessage({ id: "role.org-admin" })
    case 3:
      return formatMessage({ id: "role.employee" })
    case 4:
      return formatMessage({ id: "role.auditor" })
    default:
      return null
  }
}

export const getSearchLangOptions = formatMessage => [
  {
    value: "lang_en",
    label: formatMessage({
      id: "setting.en"
    })
  },
  {
    value: "lang_zh-TW",
    label: formatMessage({
      id: "setting.zh-TW"
    })
  },
  {
    value: "lang_zh-CN",
    label: formatMessage({
      id: "setting.zh-CN"
    })
  },
  {
    value: "lang_ja",
    label: formatMessage({
      id: "setting.ja"
    })
  },
  {
    value: "lang_ko",
    label: formatMessage({
      id: "setting.ko"
    })
  },
  {
    value: "lang_de",
    label: formatMessage({
      id: "setting.de"
    })
  },
  {
    value: "lang_fr",
    label: formatMessage({
      id: "setting.fr"
    })
  },
  {
    value: "lang_es",
    label: formatMessage({
      id: "setting.es"
    })
  },
  {
    value: "lang_ru",
    label: formatMessage({
      id: "setting.ru"
    })
  }
]

export const getRenderSearchLang = (lang, formatMessage) => {
  switch (lang) {
    case "lang_en":
      return formatMessage({ id: "setting.en" })
    case "lang_zh-TW":
      return formatMessage({ id: "setting.zh-TW" })
    case "lang_zh-CN":
      return formatMessage({ id: "setting.zh-CN" })
    case "lang_ja":
      return formatMessage({ id: "setting.ja" })
    case "lang_ko":
      return formatMessage({ id: "setting.ko" })
    case "lang_de":
      return formatMessage({ id: "setting.de" })
    case "lang_fr":
      return formatMessage({ id: "setting.fr" })
    case "lang_es":
      return formatMessage({ id: "setting.es" })
    case "lang_ru":
      return formatMessage({ id: "setting.ru" })

    default:
      return "Default"
  }
}

export const getRenderUiLang = (lang, formatMessage) => {
  switch (lang) {
    case "zh-TW":
      return formatMessage({ id: "setting.zh-hant" })
    case "en":
      return formatMessage({ id: "setting.en-us" })
    case "zh-CN":
      return formatMessage({ id: "setting.zh-cn" })

    default:
      return null
  }
}

export const getRenderTimeRange = (range, formatMessage) => {
  switch (range) {
    case -1:
      return formatMessage({ id: "search.no-limit" })
    case 1:
      return formatMessage({ id: "search.in-1-year" })
    case 2:
      return formatMessage({ id: "search.in-2-year" })
    case 5:
      return formatMessage({ id: "search.in-5-year" })
    default:
      return null
  }
}

export const getRenderMode = (mode, formatMessage) => {
  switch (mode) {
    case 1:
      return formatMessage({ id: "search.exact-mode" })
    case 0: // MARK: 根據 api 文件 0 = 預設，但是資料是依循搜尋模組 Harry 說先用模糊，有可能要再改
    case 2:
      return formatMessage({ id: "search.fuzzy-mode" })

    default:
      return null
  }
}

export const getRenderEnable = (enable, formatMessage) => {
  return enable
    ? formatMessage({ id: "setting.enabled" })
    : formatMessage({ id: "setting.disabled" })
}

export const getAccountErrorMsg = (msg, formatMessage) => {
  switch (msg) {
    case "required":
      return formatMessage({ id: "setting.add-account-required" })
    case "invalid":
      return formatMessage({ id: "setting.account-eamil-validation" })
    case "existed":
      return formatMessage({ id: "setting.add-account-existed" })
    case "duplicate":
      return formatMessage({ id: "setting.create-account-duplicate" })
    default:
      return null
  }
}

export const getRenderNegativesWords = (wordsArr, separator, formatMessage) => {
  if (wordsArr?.length === 0) {
    return formatMessage({ id: "setting.none" })
  }

  return wordsArr?.join(separator)
}

export const convertDateString = (value, format = "YYYY/MM/DD") => {
  if (value?.toString()?.length === 4) {
    return value ? moment(value, "YYYY").format("YYYY") : ""
  }
  return value ? moment(value, "YYYYMMDD").format(format) : ""
}

// 新聞風險
export const getScoreColor = score => {
  if (score <= 0.33) {
    return "#48D3A1"
  }

  if (score <= 0.66) {
    return "#FFC211"
  }

  if (score <= 1) {
    return "#E81F61"
  }

  return success
}

export const getIdvAuditStatus = (status, formatMessage) => {
  switch (status) {
    // Special case when there's no idv audit task
    case null:
      return ""
    case 0:
      return formatMessage({ id: "idv.verify-status-default" })
    case 1:
      return formatMessage({ id: "audit.acceptance-status-accept" })
    case 2:
      return formatMessage({ id: "audit.acceptance-status-reject" })
    default:
      return ""
  }
}

export const getAuditStatus = (status, formatMessage) => {
  switch (status) {
    // Special case when there's no audit task
    case null:
      return ""
    case 0:
      return formatMessage({ id: "audit.acceptance-status-default" })
    case 1:
      return formatMessage({ id: "audit.acceptance-status-accept" })
    case 2:
      return formatMessage({ id: "audit.acceptance-status-reject" })

    default:
      return formatMessage({ id: "audit.acceptance-status-processing" })
  }
}

export const getDateYMDHm = timestamp => {
  return moment(timestamp * 1000).format("YYYY/MM/DD HH:mm")
}

export const getDateYMD = timestamp => {
  return moment(timestamp * 1000).format("YYYY/MM/DD")
}

export const getGender = (value, formatMessage) => {
  switch (value) {
    case 0:
      return null
    case 1:
    case "M":
      return formatMessage({ id: "general.gender.male" })
    case 2:
    case "F":
      return formatMessage({ id: "general.gender.female" })
    default:
      return ""
  }
}

export const getTimeRange = (val, formatMessage) => {
  if (val === -1) {
    return formatMessage({ id: "search.no-limit" })
  }
  return `${val} ${formatMessage({ id: "general.year" })}`
}

export const getSortingString = (sortingArray = []) => {
  return sortingArray
    .map(sorting => `${sorting.key}:${sorting.value === true ? "a" : "d"}`)
    .join(",")
    .trim()
}

export const getAPISortingString = (sortingArray = []) => {
  return sortingArray
    .filter(sorting => sorting.value !== null)
    .map(sorting => `${sorting.key}:${sorting.value === true ? "a" : "d"}`)
    .join(",")
    .trim()
}

export const containsCJK = value => {
  const cjk_charset = cjk()

  return cjk_charset.toRegExp().test(value?.trim())
}

export const englishOnly = value => {
  const english = /^[A-Za-z0-9]*$/

  return english.test(value.trim())
}

export const isValidatedSearchString = value => {
  // 有CJK要判斷是否有混雜英數在裡面，並且字數要 > 2
  if (containsCJK(value)) {
    const words = value.replaceAll(" ", "").split("")

    return words.length >= 2
  }

  // 不包含CJK字元的情形，先把`,`, `.`, `-` 轉換成空格，並且排除掉多餘的空格
  // e.g, `John  Cena`, -> ['John', 'Cena'] instead of ['John', '', 'Cena']
  const words = value
    ?.replaceAll(",", " ")
    .replaceAll(".", " ")
    .replaceAll("-", " ")
    .split(" ")
    .filter(word => word !== "")

  if (words?.length < 2) {
    return false
  }

  return true
}

export const getIdCardType = (type, subtype, formatMessage) => {
  switch (type) {
    case "PASSPORT":
      return formatMessage({ id: "idv.passport" })
    case "ID_CARD":
      if (subtype === "RESIDENT_PERMIT_ID")
        return formatMessage({ id: "idv.resident-permit-id" })

      return formatMessage({ id: "idv.id-card" })
    case "DRIVING_LICENSE":
      return formatMessage({ id: "idv.driver-license" })
    case "NO_DATA":
      return formatMessage({ id: "search.no-data" })
    default:
      return formatMessage({ id: "idv.cannot-verify" })
  }
}

export const getPlan = (value, isTrial, formatMessage) => {
  if (isTrial) {
    return formatMessage({ id: "setting.subscription-plan-trial" })
  }
  switch (value) {
    case 1:
      return formatMessage({ id: "setting.subscription-plan-standard" })
    case 2:
      return formatMessage({ id: "setting.subscription-plan-premium" })
    case 3:
      return formatMessage({ id: "setting.subscription-plan-new-standard" })
    case 4:
      return formatMessage({ id: "setting.subscription-plan-new-premium" })
    default:
      return null
  }
}

export const getCustomerFormStatus = (value, formatMessage) => {
  switch (value) {
    case true:
      return formatMessage({ id: "setting.enabled" }) // 啟用
    case false:
      return formatMessage({ id: "setting.not-enabled" }) // 不啟用
    default:
      return ""
  }
}

export const getStatus = (value, formatMessage) => {
  switch (value) {
    case 0:
      return formatMessage({ id: "setting.subscription-status-0" }) // 待啟用
    case 1:
      return formatMessage({ id: "setting.subscription-status-1" }) // 啟用
    case 2:
      return formatMessage({ id: "setting.subscription-status-2" }) // 停用
    default:
      return ""
  }
}

// check YYYY/MM/DD format
export const isValidDateYYYYMMDD = value => {
  const re = /^\d{4}[/]\d{2}[/]\d{2}$/

  if (!value) {
    return true
  }

  return (
    moment(value, "YYYY/MM/DD").isValid() && // Validate February Days
    re.test(value) // Force format to be YYYY/MM/DD, not YYYY.MM.DD or YYYY-MM-DD
  )
}

export const download = (blob, name) => {
  const url = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

export const getCommenter = (role, formatMessage) => {
  switch (role) {
    case "SystemAdmin":
      return formatMessage({ id: "role.system-admin" })

    case "OrganizationAdmin":
      return formatMessage({ id: "role.org-admin" })

    case "Employee":
      return formatMessage({ id: "role.employee" })

    case "SearchTaskManager":
      return formatMessage({ id: "role.auditor" })

    default:
      return formatMessage({ id: "role.employee" })
  }
}
